<template>
  <v-container>
    <StoneHeader />
    <div class="regulation">
      <h1>イベント非公開</h1>
      <article>
        <dl>
          <dt>このページは現在非公開です。</dt>
          <dd>イベントの準備が出来次第、公開となります。</dd>
        </dl>
      </article>
    </div>
    <StoneFooter />
  </v-container>
</template>

<script>
import StoneHeader from '@/components/organisms/StoneHeader'
import StoneFooter from '@/components/organisms/StoneFooter'

export default {
  name: 'regulation',
  components: {
    StoneHeader,
    StoneFooter
  }
}
</script>

<style scoped>
.regulation dt{
  font-weight: bold;
  padding-top: 15px;
}
.regulation dd{
  padding-left: 20px;
}
</style>
